import * as yup from 'yup';

export const chargingHubRatingReportValidationSchema = yup.object().shape({
  monthYear: yup
    .string()
    .required('monthIsRequired')
    .matches(
      /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}|(0[1-9]|1[0-2])-\d{4}$/,
      'mustBeInFormatMonthYear'
    ),
  rating: yup
    .number()
    .required('ratingIsRequired')
    .typeError('ratingMustBeANumber'),
});
