import { ConnectorStandard } from '@energy-stacks/data';

export interface ChargingStationsModel {
  content: ChargingStation[];
  totalElements: number;
  totalPages: number;
}

export type ChargingStation = {
  id: string;
  name: string | undefined;
  model: string | undefined;
  lastUpdated: string;
  status: Status | undefined;
  connectors: Connector[];
};

export type Connector = {
  id: string;
  status: Status | undefined;
  type: ConnectorStandard | undefined;
};

export const statuses = [
  'AVAILABLE',
  'BLOCKED',
  'CHARGING',
  'INOPERATIVE',
  'OUTOFORDER',
  'PLANNED',
  'REMOVED',
  'RESERVED',
  'UNKNOWN',
] as const;
export type Status = (typeof statuses)[number];
