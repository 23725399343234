import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import {
  ESTextField,
  ESVirtualizedAutocomplete,
  showSnackbar,
} from '@energy-stacks/core/ui';
import {
  CreateProcessedRatingReportFormData,
  processedReportsApiErrors,
  reportRatingModel,
  useCreateProcessedRatingReportMutation,
  useEditProcessedRatingReportMutation,
} from '@energy-stacks/obelis/feature-charging-hub-reports-data';
import { chargingHubsApi } from '@energy-stacks/obelis/feature-charging-hubs-data';
import { useAppDispatch } from '@energy-stacks/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRatingsRawReport } from './useRatingsRawReport';
import { useProcessedRatingReport } from './useProcessedRatingReport';
import { chargingHubRatingReportValidationSchema } from '../chargingHubRatingReportValidationSchema';
import { ChargingHubReportContainer } from '../ChargingHubReportContainer';

export const ChargingHubRatingReportForm = ({
  setIsRatingFormValid,
  setIsRatingFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsRatingFormValid: (isRatingFormValid: boolean) => void;
  setIsRatingFormDirty: (isRatingFormDirty: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('chargingReports');
  const [createProcessedRatingReport, { isLoading: isCreating }] =
    useCreateProcessedRatingReportMutation();
  const [editProcessedRatingReport, { isLoading: isEditing }] =
    useEditProcessedRatingReportMutation();
  const { rawData, isLoading: isRatingsRawReportLoading } =
    useRatingsRawReport(isEditMode);
  const { data: processedData, isLoading: isProcessedRatingReportLoading } =
    useProcessedRatingReport(isEditMode);
  const { uid: reportUid, id: chargingHubUid } = useParams<{
    uid: string;
    id: string;
  }>();
  const dispatch = useAppDispatch();

  const reportData = isEditMode ? processedData : rawData;

  const defaultRatingOptions = useMemo(() => {
    return {
      monthYear: reportData?.monthYear ?? '',
      rating: reportData?.rating,
    };
  }, [reportData]);

  const formMethods = useForm<CreateProcessedRatingReportFormData>({
    defaultValues: defaultRatingOptions,
    mode: 'onTouched',
    resolver: yupResolver(chargingHubRatingReportValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
  } = formMethods;

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled =
    isProcessedRatingReportLoading ||
    isRatingsRawReportLoading ||
    isEditing ||
    isCreating;

  const submitChargingHubRatingReportForm: SubmitHandler<CreateProcessedRatingReportFormData> =
    useCallback(
      (data) => {
        const { monthYear, rating } = data;
        const successMessage = isEditMode
          ? 'updateChargingHubReportRatingSuccess'
          : 'createChargingHubReportRatingSuccess';

        const reportHandler = isEditMode
          ? editProcessedRatingReport({
              uid: reportUid ?? '',
              body: {
                monthYear,
                rating,
              },
            })
          : createProcessedRatingReport({
              chargingHubUid: chargingHubUid ?? '',
              monthYear,
              rating,
            });

        reportHandler
          .unwrap()
          .then(() => {
            showSnackbar('success', successMessage, 'chargingReports');
            dispatch(
              chargingHubsApi.util.invalidateTags(['ChargingHubReports'])
            );
            handleClose();
          })
          .catch((error) => {
            showSnackbar(
              'error',
              processedReportsApiErrors[error.data?.errorCode],
              'chargingReports'
            );
          });
      },
      [
        chargingHubUid,
        createProcessedRatingReport,
        editProcessedRatingReport,
        handleClose,
        isEditMode,
        reportUid,
        dispatch,
      ]
    );

  useEffect(() => {
    setIsRatingFormValid &&
      setIsRatingFormValid(isFormValid || isCreating || isEditing);
    setIsRatingFormDirty && setIsRatingFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [
    isCreating,
    isEditing,
    isFormValid,
    isFormDirty,
    isDisabled,
    setIsRatingFormDirty,
    setIsRatingFormValid,
    setIsDisabled,
  ]);

  useEffect(() => {
    resetForm(defaultRatingOptions);
  }, [resetForm, defaultRatingOptions]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(submitChargingHubRatingReportForm)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 124px)',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <ChargingHubReportContainer
          title="rating"
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isEditing || isCreating}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('month') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.monthYear ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="monthYear"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['monthYear'])}
                      helperText={
                        Boolean(errors['monthYear']) &&
                        t(`${errors['monthYear']?.message}`)
                      }
                      disabled={isDisabled}
                      placeholder={reportData?.monthYear ?? '-'}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('rating') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.rating ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="rating"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      placeholder="-"
                      required
                      disabled={isDisabled}
                      value={{
                        label: value ?? '',
                        value: value ?? '',
                      }}
                      options={reportRatingModel.map((curr) => ({
                        label: curr,
                        value: curr,
                      }))}
                      noOptionsText={t(
                        'createChargingHubRatingReportNoOptions'
                      )}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange(value.value ?? '');
                        }
                      }}
                      error={Boolean(errors['rating'])}
                      helperText={
                        Boolean(errors['rating']) &&
                        t(`${errors['rating']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </ChargingHubReportContainer>
      </form>
    </FormProvider>
  );
};
