import { ChargingHubModel } from '../chargingHubModel';
import { ChargingHubDto } from '../chargingHubsDto';

export const chargingHubsNormalizer = (
  chargingHub: ChargingHubDto
): ChargingHubModel => {
  return {
    id: chargingHub.chargingHubUid || '-',
    address: chargingHub.address || '-',
    chargingStations: chargingHub.chargingStations || 0,
    city: chargingHub.city || '-',
    coordinates: chargingHub.coordinates || '-',
    lastModifiedDate: chargingHub.lastModifiedDate || '-',
    name: chargingHub.name || '-',
  };
};
