import { appendZToDateString } from '@energy-stacks/shared';
import {
  ChargingStationDto,
  ConnectorDto,
  EvseStatusDto,
} from '../chargingStationsDto';
import { Connector } from '../chargingStationsModel';
import { connectorStandardDtoToTypeMap } from '@energy-stacks/data';

export const chargingStationsNormalizer = (
  chargingStationsDto: ChargingStationDto[]
) => {
  return chargingStationsDto.map((chargingStationDto) => ({
    id: chargingStationDto.identityKey,
    name: chargingStationDto.name,
    model: chargingStationDto.model,
    lastUpdated: appendZToDateString(chargingStationDto.lastUpdated),
    status: chargingStationDto.evse[0].status,
    // Each EVSE will have only one connector
    connectors: chargingStationDto.evse?.map((evse) => {
      return connectorsNormalizer(evse.connectors[0], evse.status);
    }),
  }));
};

const connectorsNormalizer = (
  connectorDto: ConnectorDto,
  evseStatus: EvseStatusDto | undefined
): Connector => {
  return {
    id: connectorDto.externalConnectorId ?? '',
    status: evseStatus,
    type: connectorDto.standard
      ? connectorStandardDtoToTypeMap[connectorDto.standard]
      : undefined,
  };
};
