import { Evse } from '@energy-stacks/obelis/feature-charging-stations-data';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GreyCheckboxIcon } from '@energy-stacks/obelis/shared';
import { ChargingStationDetailsEvseCardHeader } from './ChargingStationDetailsEvseCardHeader';
import { ChargingStationDetailsConnectorCard } from './ChargingStationDetailsConnectorCard';

type ChargingStationDetailsEvseCardProps = {
  evse: Evse;
};

export const ChargingStationDetailsEvseCard: FC<
  ChargingStationDetailsEvseCardProps
> = ({ evse }) => {
  const [t] = useTranslation('chargingStations');

  return (
    <Paper
      sx={{
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      <ChargingStationDetailsEvseCardHeader
        evseId={evse.evseId}
        status={evse.status}
      />
      <Stack sx={{ p: 4 }}>
        <Typography
          sx={({ spacing }) => ({
            fontSize: spacing(3),
            fontWeight: 400,
            lineHeight: spacing(3),
            mb: 2,
          })}
        >
          {t('evseCardCapabilitiesLabel')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {evse.capabilities.map((c) => {
            return (
              <Box
                key={c}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  mb: 1,
                  mr: 6,
                }}
              >
                <GreyCheckboxIcon />
                <Typography
                  sx={({ spacing }) => ({
                    fontSize: spacing(3.5),
                    fontWeight: 400,
                    lineHeight: spacing(5),
                    ml: 2,
                  })}
                >
                  {t(`evseCapabilities.${c}`)}
                </Typography>
              </Box>
            );
          })}
        </Box>
        {evse.connectors.map((connector) => {
          return (
            <ChargingStationDetailsConnectorCard
              key={connector.id}
              connector={connector}
            />
          );
        })}
      </Stack>
    </Paper>
  );
};
