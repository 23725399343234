import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { rawRatingReportsNormalizer } from './rating-reports/raw-reports/normalizers/rawRatingReportsNormalizer';
import { RawRatingReportDto } from './rating-reports/raw-reports/rawRatingReportDto';
import { RawRatingReportModel } from './rating-reports/raw-reports/rawRatingReportModel';

interface GetRawReportRatingBody {
  chargingHubUid: string;
  monthYear: string;
}

export const chargingHubRawReportsApi = createApi({
  reducerPath: 'rawReportsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/raw-reports`),
  keepUnusedDataFor: 0,
  tagTypes: ['rawReports'],
  endpoints: (builder) => ({
    getRatingRawReport: builder.query<
      RawRatingReportModel,
      GetRawReportRatingBody
    >({
      query: ({ chargingHubUid, monthYear }) => ({
        url: `charging-hub/${chargingHubUid}/ratings/${monthYear}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'rawReports',
          id: args.chargingHubUid,
        },
      ],
      transformResponse: (response: RawRatingReportDto) => {
        return rawRatingReportsNormalizer(response);
      },
    }),
  }),
});

export const { useGetRatingRawReportQuery } = chargingHubRawReportsApi;
