import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconChevronLeft } from '@tabler/icons-react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { ESDialogActionButton, ESDialogActions } from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';

interface ChargingHubReportContainerProps {
  title: string;
  children: ReactNode;
  isFormValid: boolean;
  isFormDirty: boolean;
  isLoading: boolean;
  activeStep: number;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  isEditMode?: boolean;
}

export const ChargingHubReportContainer = ({
  title,
  children,
  isFormValid,
  isFormDirty,
  isLoading,
  setActiveStep,
  isEditMode,
}: ChargingHubReportContainerProps) => {
  const { t } = useTranslation('chargingReports');
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const showExitConfirmation = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: () => {
      if (isEditMode) {
        navigate(`/charging-hubs/${id}/reports`);
      } else {
        setActiveStep && setActiveStep((prev) => prev - 1);
      }
    },
  });

  return (
    <>
      <Card variant="outlined" sx={{ borderRadius: '12px', marginBottom: 6 }}>
        <CardContent
          sx={{
            padding: '0 !important',
          }}
        >
          <Grid container direction="column">
            <Grid
              item
              xs={12}
              sx={{
                padding: '16px',
                backgroundColor: 'grey.100',
              }}
            >
              <Typography
                sx={{
                  color: 'grey.900',
                  fontSize: 14,
                  fontWeight: 700,
                  lineHeight: 'normal',
                }}
              >
                {t(title)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                borderBottom: '1px solid',
                borderColor: 'grey.100',
                alignItems: 'center',
                paddingX: '8px',
              }}
            />
          </Grid>
          <Grid
            container
            sx={{
              padding: '16px',
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'grey.100',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight={700}>
                {t('key')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight={700}>
                {t('originalValue')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight={700}>
                {t('newValue')}
              </Typography>
            </Grid>
          </Grid>
          {children}
        </CardContent>
      </Card>
      <ESDialogActions sx={{ paddingX: 0 }}>
        <ESDialogActionButton
          loading={isLoading}
          variant="text"
          onClick={() => {
            showExitConfirmation();
          }}
          sx={{
            mr: 'auto',
            '&.MuiButton-outlined': {
              borderColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.light',
                borderColor: 'primary.main',
              },
            },
          }}
        >
          <IconChevronLeft style={{ marginRight: 12, height: 20 }} />
          {t('createReportStepBackButtonLabel')}
        </ESDialogActionButton>
        <ESDialogActionButton
          variant="contained"
          type="submit"
          disabled={!isFormValid}
          loading={isLoading}
        >
          {t(
            `${
              isEditMode
                ? `editReportStepActionButton`
                : `createReportStepActionButtonLabels.${1}`
            }`
          )}
        </ESDialogActionButton>
      </ESDialogActions>
    </>
  );
};
