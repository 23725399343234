import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { processedReportsNormalizer } from './charging-station-processed-reports/normalizers/processedReportsNormalizer';
import { ProcessedReportsPageEntry } from './charging-station-processed-reports/processedReportsPageEntry';
import { ProcessedReportModel } from './charging-station-processed-reports/processedReportModel';
import { GetProcessedReportsBody } from './charging-station-processed-reports/getProcessedReportsBody';

export const chargingStationProcessedReportsApi = createApi({
  reducerPath: 'chargingStationProcessedReportsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/charging-stations`),
  tagTypes: ['ChargingStationReports'],
  endpoints: (builder) => ({
    getChargingStationProcessedReports: builder.query<
      ProcessedReportModel,
      GetProcessedReportsBody
    >({
      query: ({ identityKey, monthYear, ...rest }) => ({
        url: `/${identityKey}/processed-reports/month/${monthYear}`,
        params: rest,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'ChargingStationReports',
          id: args?.identityKey,
        },
      ],
      transformResponse: (
        processedChargingReports: ProcessedReportsPageEntry
      ) => {
        const { totalElements, totalPages, content } = processedChargingReports;

        return {
          totalElements: totalElements ?? 0,
          totalPages: totalPages ?? 0,
          content: processedReportsNormalizer(content ?? []),
        };
      },
    }),
  }),
});

export const { useGetChargingStationProcessedReportsQuery } =
  chargingStationProcessedReportsApi;
