import { ESTableBodyCell, ESTableBodyRow } from '@energy-stacks/core/ui';
import { ChargingPeriodModel } from '@energy-stacks/obelis/feature-charging-sessions-data';
import { formatDateTime } from '@energy-stacks/shared';
import { Box, Collapse, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ChargingSessionsTableRowProps {
  expanded: boolean;
  chargingPeriods: ChargingPeriodModel[] | null;
  colSpan: number;
}

export const ChargingSessionsTableRow: React.FC<
  ChargingSessionsTableRowProps
> = ({ chargingPeriods, colSpan, expanded }) => {
  const { t } = useTranslation('chargingSessions');

  return (
    <ESTableBodyRow rowHeight="unset">
      <ESTableBodyCell
        colSpan={colSpan}
        sx={{
          borderBottom: expanded ? 'auto' : 'unset',
          backgroundColor: (theme) => theme.palette.grey[50],
          p: 0,
        }}
      >
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Stack p={4} pb={0}>
            {chargingPeriods?.map((chargingPeriod, index) => {
              return (
                <Box key={index} mb={3}>
                  <Typography
                    mb={3}
                    sx={{ fontWeight: 500 }}
                    color="grey.900"
                    fontSize={14}
                  >
                    {`${t('chargingPeriod')} ${index + 1}: ${formatDateTime(
                      chargingPeriod.startDate
                    )}`}
                  </Typography>
                  <Grid columnSpacing={6} container>
                    {chargingPeriod.dimensions.map((dimension, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Grid item xs={4}>
                            <Box
                              sx={{
                                backgroundColor: (theme) =>
                                  theme.palette.grey[200],
                                borderRadius: (theme) => theme.spacing(3),
                                p: 3,
                                mb: 3,
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                mb={3}
                              >
                                <Typography color="grey.500" fontSize={14}>
                                  {t('type')}
                                </Typography>
                                <Typography
                                  mb={3}
                                  color="grey.500"
                                  fontSize={14}
                                >
                                  {t(dimension.type)}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography color="grey.500" fontSize={14}>
                                  {t('volume')}
                                </Typography>{' '}
                                <Typography color="grey.500" fontSize={14}>
                                  {dimension.value}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </Box>
              );
            })}
          </Stack>
        </Collapse>
      </ESTableBodyCell>
    </ESTableBodyRow>
  );
};
