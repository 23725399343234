export const ObelisRoutes = {
  ChargingHubs: '/charging-hubs',
  AddChargingHub: 'add',
  ChargingHubDetailsGeneralTab: 'general',
  ChargingHubDetailsChargingStationsTab: 'charging-stations',
  ChargingHubDetailsReportsTab: 'reports',
  ChargingHubDetails: ':id',
  EditChargingHubFromTable: ':name/:id/edit',
  EditChargingHub: 'edit',
  ChargingSessions: '/charging-sessions',
  ChargingStations: '/charging-stations',
  ChargingStationDetails: ':id',
  ChargingStationDetailsGeneralTab: 'general',
  CreateReport: 'create',
  EditReport: ':uid/edit',
  ChargingStationDetailsSessionsTab: 'sessions',
  AddChargingStation: 'add',
  ChargingStationDetailsReportsTab: 'reports',
};
