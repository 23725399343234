import { useTranslation } from 'react-i18next';
import { ChargingStationDetails } from '@energy-stacks/obelis/feature-charging-stations-data';
import { FC, useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';

type ChargingStationDetailsGeneralCardProps = {
  chargingStationDetails: ChargingStationDetails;
};
export const NO_VALUE = 'N/A';

export const ChargingStationDetailsGeneralCard: FC<
  ChargingStationDetailsGeneralCardProps
> = ({ chargingStationDetails }) => {
  const [t] = useTranslation('chargingStations');

  const generalCardValues: Omit<ChargingStationDetails, 'evses'> = useMemo(
    () => ({
      id: chargingStationDetails?.id ?? NO_VALUE,
      name: chargingStationDetails?.name ?? NO_VALUE,
      chargingHubName: chargingStationDetails?.chargingHubName ?? NO_VALUE,
      vendor: chargingStationDetails?.vendor ?? NO_VALUE,
      vendorModel: chargingStationDetails?.vendorModel ?? NO_VALUE,
    }),
    [chargingStationDetails]
  );

  return (
    <Paper
      sx={{
        border: 'none',
        height: '100%',
        p: 4,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: 'grey.900',
          fontWeight: 700,
          pb: 4,
          wordBreak: 'break-word',
        }}
      >
        {t('chargingStationDetailsGeneralCardTitle')}
      </Typography>
      {Object.entries(generalCardValues).map(([prop, value], index, arr) => (
        <Box
          key={prop}
          sx={({ palette }) => ({
            alignItems: 'center',
            borderBottom:
              index === arr.length - 1
                ? 'none'
                : `1px solid ${palette.grey[100]}`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingY: 2,
          })}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'grey.900',
              lineHeight: 1.5,
              mr: 3,
            }}
          >
            {t(`chargingStationDetailsGeneralCardLabels.${prop}`)}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: 'grey.900',
              fontWeight: 500,
              lineHeight: 1.5,
              textAlign: 'right',
            }}
          >
            {value}
          </Typography>
        </Box>
      ))}
    </Paper>
  );
};
