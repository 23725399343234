import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { ChargingStationsModel } from './chargingStationsModel';
import { chargingStationsNormalizer } from './normalizers/chargingStationsNormalizer';
import { ChargingStationsPageEntry } from './chargingStationsPageEntry';

type GetChargingStationsBody = {
  pageNumber: number;
  pageSize: number;
  name?: string;
  evseId?: string;
};

export const chargingStationsApi = createApi({
  reducerPath: 'chargingStationsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/charging-stations`),
  tagTypes: ['ChargingStations'],
  endpoints: (builder) => ({
    getChargingStations: builder.query<
      ChargingStationsModel,
      GetChargingStationsBody
    >({
      query: (searchParams) => ({
        url: '/search',
        params: searchParams,
      }),
      providesTags: ['ChargingStations'],
      transformResponse: (chargingStations: ChargingStationsPageEntry) => {
        return {
          totalElements: chargingStations.totalElements ?? 0,
          totalPages: chargingStations.totalPages ?? 0,
          content: chargingStationsNormalizer(chargingStations.content ?? []),
        };
      },
    }),
  }),
});

export const { useGetChargingStationsQuery } = chargingStationsApi;
