import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import {
  ProcessedReportModel,
  processedReportsNormalizer,
} from '@energy-stacks/obelis/feature-charging-hub-reports-data';
import { ChargingHubsPageEntry } from './chargingHubsPageEntry';
import { chargingHubsNormalizer } from './normalizers/chargingHubsNormalizer';
import { AuxiliaryFacilityDto, StateDto } from '@energy-stacks/obelis/shared';
import { chargingHubDetailsNormalizer } from './normalizers/chargingHubDetailsNormalizer';
import { ChargingHubsModel } from './chargingHubModel';
import { ChargingHubDetailsDto } from './chargingHubDetailsDto';
import { ChargingHubDetailsModel } from './chargingHubDetailsModel';
import { ProcessedReportsPageEntry } from './processedReportsPageEntry';

export interface GetChargingHubsBody {
  searchValue?: string;
  pageNumber: number;
  pageSize: number;
}

interface GetProcessedReportsBody {
  pageNumber: number;
  pageSize: number;
  chargingHubUid: string;
  monthYear: string;
}

interface AddChargingHubFormData {
  id: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  state: StateDto;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  auxiliaryFacilities: AuxiliaryFacilityDto[] | null;
}

interface EditChargingHubRequestData {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  state: StateDto;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  auxiliaryFacilities: AuxiliaryFacilityDto[] | null;
}

export const chargingHubsApi = createApi({
  reducerPath: 'chargingHubsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/charging-hubs`),
  keepUnusedDataFor: 0,
  tagTypes: [
    'ChargingHubs',
    'ChargingHubDetails',
    'ChargingHubReports',
    'AllChargingHubs',
  ],
  endpoints: (builder) => ({
    getChargingHubs: builder.query<ChargingHubsModel, GetChargingHubsBody>({
      query: (searchParams) => ({
        url: `/search`,
        method: 'GET',
        params: {
          ...searchParams,
          searchValue: searchParams.searchValue,
        },
      }),
      providesTags: ['ChargingHubs'],
      transformResponse: (chargingHubs: ChargingHubsPageEntry) => {
        return {
          totalElements: chargingHubs.totalElements ?? 0,
          totalPages: chargingHubs.totalPages ?? 0,
          chargingHubs: chargingHubs.content?.map((item) => {
            return chargingHubsNormalizer(item);
          }),
        };
      },
    }),
    getAllChargingHubs: builder.query<{ id: string; name: string }[], void>({
      query: () => '/overview',
      providesTags: ['AllChargingHubs'],
      transformResponse: (
        chargingHubNames: { chargingHubUid?: string; name?: string }[]
      ) =>
        chargingHubNames.map((chargingHub) => ({
          id: chargingHub.chargingHubUid ?? '',
          name: chargingHub.name ?? '',
        })),
    }),
    addChargingHub: builder.mutation<void, AddChargingHubFormData>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ChargingHubs'],
    }),
    editChargingHub: builder.mutation<
      void,
      { body: EditChargingHubRequestData; id: string }
    >({
      query: ({ body, id }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ type: 'ChargingHubDetails', id: arg.id }, 'ChargingHubs']
          : [],
    }),
    deleteChargingHub: builder.mutation<void, string>({
      query: (chargingHubId) => ({
        url: `/${chargingHubId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ChargingHubs'],
    }),
    getChargingHubDetails: builder.query<ChargingHubDetailsModel, string>({
      query: (id) => ({
        url: id,
        method: 'GET',
      }),
      providesTags: (result, _error, _arg) => [
        {
          type: 'ChargingHubDetails',
          id: result?.id,
        },
      ],
      transformResponse: (response: ChargingHubDetailsDto) => {
        return chargingHubDetailsNormalizer(response);
      },
    }),
    getChargingHubProcessedReports: builder.query<
      ProcessedReportModel,
      GetProcessedReportsBody
    >({
      query: ({ chargingHubUid, monthYear, ...rest }) => ({
        url: `/${chargingHubUid}/processed-reports/month/${monthYear}`,
        params: rest,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'ChargingHubReports',
          id: args?.chargingHubUid,
        },
      ],
      transformResponse: (
        processedChargingReports: ProcessedReportsPageEntry
      ) => {
        const { totalElements, totalPages, content } = processedChargingReports;

        return {
          totalElements: totalElements ?? 0,
          totalPages: totalPages ?? 0,
          content: processedReportsNormalizer(content ?? []),
        };
      },
    }),
  }),
});

export const {
  useGetChargingHubsQuery,
  useGetAllChargingHubsQuery,
  useAddChargingHubMutation,
  useGetChargingHubDetailsQuery,
  useDeleteChargingHubMutation,
  useEditChargingHubMutation,
  useGetChargingHubProcessedReportsQuery,
} = chargingHubsApi;
