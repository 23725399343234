import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
  translationsEn as sharedTranslationsEn,
  translationsDe as sharedTranslationsDe,
} from '@energy-stacks/shared';

import {
  translationsEn as chargingHubsTranslationsEn,
  translationsDe as chargingHubsTranslationsDe,
} from '@energy-stacks/obelis/feature-charging-hubs';

import {
  translationsEn as chargingStationsTranslationsEn,
  translationsDe as chargingStationsTranslationsDe,
} from '@energy-stacks/obelis/feature-charging-stations';

enum Language {
  EN = 'en',
  DE = 'de',
}

export const resources = {
  [Language.EN]: {
    chargingHubs: chargingHubsTranslationsEn,
    chargingStations: chargingStationsTranslationsEn,
    shared: sharedTranslationsEn,
  },
  [Language.DE]: {
    chargingHubs: chargingHubsTranslationsDe,
    chargingStations: chargingStationsTranslationsDe,
    shared: sharedTranslationsDe,
  },
};

// NOTE: Must be exported and used
// or else the bundler will ignore it.
export const i18nextInitialization = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      caches: ['localStorage'],
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    fallbackLng: Language.EN,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });
