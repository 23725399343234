import {
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { useGetAllChargingHubsQuery } from '@energy-stacks/obelis/feature-charging-hubs-data';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AddChargingStationChargingHubSelectProps = {
  closeDialog: () => void;
};

export const AddChargingStationChargingHubSelect: FC<
  AddChargingStationChargingHubSelectProps
> = ({ closeDialog }) => {
  const [t] = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();

  const {
    data: allChargingHubs,
    isLoading,
    isError,
  } = useGetAllChargingHubsQuery();

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const chargingHubOptions = useMemo(() => {
    return (allChargingHubs ?? []).map((hub) => ({
      label: hub.name,
      value: hub.id,
    }));
  }, [allChargingHubs]);

  useEffect(() => {
    if (isError) {
      closeDialog();
      showSnackbar('error', 'fetchingChargingHubsFailed', 'chargingStations');
    }
  }, [isError, closeDialog, showSnackbar]);

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      render={({ field: { value, onChange, onBlur } }) => (
        <ESVirtualizedAutocomplete
          value={{
            value: value?.value ?? '',
            label: value?.label ?? '',
          }}
          options={chargingHubOptions}
          label={t('addChargingStationGeneralDetailsLabels.chargingHub')}
          required
          disabled={isLoading}
          error={Boolean(errors['chargingHub'])}
          helperText={
            Boolean(errors['chargingHub']) &&
            t(
              `addChargingStationGeneralDetailsErrors.${errors['chargingHub']?.value?.message}`
            )
          }
          onChange={(_, value) => {
            if (Array.isArray(value)) {
              return;
            }
            if (value) {
              onChange({ label: value.label, value: value.value });
            }
          }}
          onBlur={onBlur}
        />
      )}
      name="chargingHub"
    />
  );
};
