import {
  Evse,
  Status,
} from '@energy-stacks/obelis/feature-charging-stations-data';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { statusColorsMap } from '../statusColorsMap';
import { FC } from 'react';

type EvseCardHeaderProps = {
  evseId: Evse['evseId'];
  status: Evse['status'];
};

export const ChargingStationDetailsEvseCardHeader: FC<EvseCardHeaderProps> = ({
  evseId,
  status,
}) => {
  const [t] = useTranslation('chargingStations');

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'dark.800',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 4,
      }}
    >
      <Stack>
        <Typography
          sx={({ spacing }) => ({
            color: 'dark.light',
            fontSize: spacing(3),
            fontWeight: 400,
            lineHeight: spacing(5),
            textTransform: 'uppercase',
          })}
        >
          {t('evseCardHeaderIdLabel')}
        </Typography>
        <Typography
          sx={({ spacing }) => ({
            color: 'background.paper',
            fontSize: spacing(4),
            fontWeight: 500,
          })}
        >
          {evseId}
        </Typography>
      </Stack>
      {status && <EvseStatusChip status={status} />}
    </Box>
  );
};

const EvseStatusChip = ({ status }: { status: Status }) => {
  const [t] = useTranslation('chargingStations');
  const { bgColor, color } = statusColorsMap[status];

  return (
    <Chip
      label={t(`statuses.${status}`)}
      size="small"
      sx={{
        backgroundColor: bgColor,
        color: color,
        px: 1,
      }}
    />
  );
};
