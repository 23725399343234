import { usePagination, useSort } from '@energy-stacks/core/ui';
import {
  ESPage,
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChargingStations } from './useChargingStations';
import { ChargingStationsTable } from './ChargingStationsTable';

export const ChargingStationsPage = () => {
  const [t] = useTranslation('chargingStations');
  const pageTitle = t('title');
  useDocumentTitle(pageTitle);
  const { pagination, setPagination } = usePagination();
  const { sorting, setSorting } = useSort();
  const [search, setSearch] = useState('');

  const { chargingStations, isLoading, isError, refetch } = useChargingStations(
    {
      search,
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
    }
  );

  return (
    <ESPage title={pageTitle}>
      {isLoading && <ESPageLoadingIndicator />}
      {isError && <RefetchOnError onRefetch={refetch} />}
      {chargingStations ? (
        <ChargingStationsTable
          chargingStations={chargingStations}
          search={search}
          setSearch={setSearch}
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting}
          onSortingChange={setSorting}
        />
      ) : null}
    </ESPage>
  );
};
