import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
  translationsEn as sharedTranslationsEn,
  translationsDe as sharedTranslationsDe,
  chargingStationsTranslationsEn as sharedChargingStationsTranslationsEn,
  chargingStationsTranslationsDe as sharedChargingStationsTranslationsDe,
} from '@energy-stacks/shared';

import {
  translationsEn as chargingHubsTranslationsEn,
  translationsDe as chargingHubsTranslationsDe,
} from '@energy-stacks/obelis/feature-charging-hubs';

import {
  translationsEn as chargingSessionsTranslationsEn,
  translationsDe as chargingSessionsTranslationsDe,
} from '@energy-stacks/obelis/feature-charging-sessions';

import {
  translationsEn as chargingStationsTranslationsEn,
  translationsDe as chargingStationsTranslationsDe,
} from '@energy-stacks/obelis/feature-charging-stations';

import {
  translationsEn as userAccessTranslationsEn,
  translationsDe as userAccessTranslationsDe,
} from '@energy-stacks/user-access';

import {
  translationsEn as chargingReportsTranslationsEn,
  translationsDe as chargingReportsTranslationsDe,
} from '@energy-stacks/obelis/feature-charging-hub-reports';

import {
  translationsEn as chargingStationReportsTranslationsEn,
  translationsDe as chargingStationReportsTranslationsDe,
} from '@energy-stacks/obelis/feature-charging-station-reports';

enum Language {
  EN = 'en',
  DE = 'de',
}

export const resources = {
  [Language.EN]: {
    chargingHubs: chargingHubsTranslationsEn,
    chargingReports: chargingReportsTranslationsEn,
    chargingStations: chargingStationsTranslationsEn,
    sharedChargingStations: sharedChargingStationsTranslationsEn,
    shared: sharedTranslationsEn,
    userAccess: userAccessTranslationsEn,
    chargingSessions: chargingSessionsTranslationsEn,
    chargingStationReports: chargingStationReportsTranslationsEn,
  },
  [Language.DE]: {
    chargingHubs: chargingHubsTranslationsDe,
    chargingReports: chargingReportsTranslationsDe,
    chargingStations: chargingStationsTranslationsDe,
    sharedChargingStations: sharedChargingStationsTranslationsDe,
    shared: sharedTranslationsDe,
    userAccess: userAccessTranslationsDe,
    chargingSessions: chargingSessionsTranslationsDe,
    chargingStationReports: chargingStationReportsTranslationsDe,
  },
};

// NOTE: Must be exported and used
// or else the bundler will ignore it.
export const i18nextInitialization = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      caches: ['localStorage'],
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    fallbackLng: Language.EN,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });
