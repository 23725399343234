import { convertReportRatingDtoToModel } from '../../normalizers/reportRatingNormalizer';
import { ProcessedRatingReportDto } from '../processedRatingReportDto';
import { ProcessedRatingReportModel } from '../processedRatingReportModel';
import { ProcessedReportDto } from '../processedReportDto';
import { ProcessedReport } from '../processedReportModel';
import { ProcessedReportNameDto } from '../processedReportNameDto';
import { ProcessedReportNameModel } from '../processedReportNameModel';

export const processedRatingReportsNormalizer = (
  processedRatingReportDto: ProcessedRatingReportDto
): ProcessedRatingReportModel => {
  const { uid, monthYear, chargingHubUid, rating } = processedRatingReportDto;

  return {
    uid,
    monthYear,
    chargingHubUid,
    rating: convertReportRatingDtoToModel(rating),
  };
};

export const processedReportsNormalizer = (
  processedReportsDto: ProcessedReportDto[]
): ProcessedReport[] => {
  return processedReportsDto.map((processedReportDto) => {
    const {
      reportName,
      reportDate,
      reportedBy,
      modifiedBy,
      uid,
      lastModifiedDate,
    } = processedReportDto;

    return {
      reportName: convertProcessedReportNameDtoToModel(reportName),
      reportDate,
      reportedBy,
      modifiedBy,
      uid,
      lastModifiedDate,
    };
  });
};

const convertProcessedReportNameDtoToModel = (
  processedReportNameDto: ProcessedReportNameDto
): ProcessedReportNameModel => {
  return processedReportNameDtoToModelMap[processedReportNameDto];
};

const processedReportNameDtoToModelMap: Record<
  ProcessedReportNameDto,
  ProcessedReportNameModel
> = {
  AD_HOC_TARIFF: 'adHocTariff',
  FACILITY_DAMAGES: 'facilityDamages',
  RATING: 'rating',
} as const;
