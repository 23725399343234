import { RefetchOnError } from '@energy-stacks/shared';
import { Grid } from '@mui/material';
import { NoChargingStationDetails } from './NoChargingStationDetails';
import { ChargingStationDetailsGeneralCard } from './ChargingStationDetailsGeneralCard';
import { ChargingStationDetailsEvseCard } from './ChargingStationDetailsEvseCard';
import { useChargingStationDetails } from './useChargingStationDetails';

export const ChargingStationDetailsGeneralTab = () => {
  const { chargingStationDetails, isError, isSuccess, refetch } =
    useChargingStationDetails();

  return (
    <>
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {!chargingStationDetails && isSuccess ? (
        <NoChargingStationDetails onRefetch={refetch} />
      ) : null}
      {chargingStationDetails ? (
        <Grid container spacing={5}>
          <Grid item sm={12} md={12} lg={5} xl={4}>
            <ChargingStationDetailsGeneralCard
              chargingStationDetails={chargingStationDetails}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={7}
            xl={8}
            sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}
          >
            {chargingStationDetails?.evses.map((evse) => {
              return (
                <ChargingStationDetailsEvseCard key={evse.evseId} evse={evse} />
              );
            })}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
