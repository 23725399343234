import { appendZToDateString } from '@energy-stacks/shared';
import {
  ChargingPointDto,
  ChargingStationDetailsConnectorFormatDto,
  ChargingStationDetailsDto,
} from '../chargingStationDetailsDto';
import {
  ChargingStationDetails,
  ConnectorFormat,
  Evse,
} from '../chargingStationDetailsModel';
import { connectorStandardDtoToTypeMap } from '@energy-stacks/data';

export const chargingStationDetailsNormalizer = (
  chargingStationDetailsDto: ChargingStationDetailsDto
): ChargingStationDetails => ({
  id: chargingStationDetailsDto.identityKey ?? '',
  name: chargingStationDetailsDto.name ?? '',
  chargingHubName: chargingStationDetailsDto.chargingHub?.name ?? '',
  vendorModel: chargingStationDetailsDto.shadow.model ?? '',
  vendor: chargingStationDetailsDto.shadow.vendor ?? '',
  evses: (chargingStationDetailsDto.chargingPoints ?? []).map(
    chargingPointNormalizer
  ),
});

const chargingPointNormalizer = (chargingPointDto: ChargingPointDto): Evse => ({
  evseId: chargingPointDto.evseId ?? '',
  status: chargingPointDto.status,
  capabilities: chargingPointDto.capabilities ?? [],
  connectors:
    chargingPointDto.connectors?.map((c) => ({
      id: c.connectorUid ?? '',
      standard: c.standard && connectorStandardDtoToTypeMap[c.standard],
      powerType: c.powerType,
      format: c.format ? shadowConnectorFormatMap[c.format] : undefined,
      voltage: c.voltage,
      amperage: c.amperage,
      maxPower: c.maxPower,
      lastUpdated: appendZToDateString(chargingPointDto.statusLastUpdatedAt),
    })) ?? [],
});

const shadowConnectorFormatMap: Record<
  ChargingStationDetailsConnectorFormatDto,
  ConnectorFormat
> = {
  SOCKET: 'socket',
  CABLE: 'cable',
} as const;
