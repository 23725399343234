import { DEFAULT_PAGINATION_CONFIG } from '@energy-stacks/core/ui';
import { useGetChargingStationsQuery } from '@energy-stacks/obelis/feature-charging-stations-data';

interface GetChargingStationsConfig {
  search: string;
  chargingHubUid?: string;
  pagination: {
    page: number;
    size: number;
  };
}

export const useChargingStations = (config?: GetChargingStationsConfig) => {
  const {
    data: chargingStations,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetChargingStationsQuery({
    searchValue: config?.search ? config.search : undefined,
    pageNumber: config?.pagination?.page ?? DEFAULT_PAGINATION_CONFIG.page,
    pageSize: config?.pagination?.size ?? DEFAULT_PAGINATION_CONFIG.size,
  });

  return {
    chargingStations,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  };
};
