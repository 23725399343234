import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { ReportRatingModel } from './rating-reports/reportRatingModel';
import { ProcessedRatingReportModel } from './rating-reports/processed-reports/processedRatingReportModel';
import { ProcessedRatingReportDto } from './rating-reports/processed-reports/processedRatingReportDto';
import { processedRatingReportsNormalizer } from './rating-reports/processed-reports/normalizers/processedReportsNormalizer';
import { convertReportRatingModelToDto } from './rating-reports/normalizers/reportRatingNormalizer';

export interface CreateProcessedRatingReportFormData {
  chargingHubUid: string;
  monthYear: string;
  rating: ReportRatingModel;
}

export interface EditProcessedRatingReportFormData {
  monthYear: string;
  rating: ReportRatingModel;
}

interface GetProcessedRatingReportBody {
  uid: string;
}

export const chargingHubProcessedReportsApi = createApi({
  reducerPath: 'processedReportsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/processed-reports`),
  keepUnusedDataFor: 0,
  tagTypes: ['processedReports'],
  endpoints: (builder) => ({
    createProcessedRatingReport: builder.mutation<
      void,
      CreateProcessedRatingReportFormData
    >({
      query: (data) => ({
        url: `charging-hub/ratings`,
        method: 'POST',
        body: {
          ...data,
          rating: convertReportRatingModelToDto(data.rating),
        },
      }),
      invalidatesTags: ['processedReports'],
    }),
    getProcessedRatingReportByReportUid: builder.query<
      ProcessedRatingReportModel,
      GetProcessedRatingReportBody
    >({
      query: ({ uid }) => ({
        url: `charging-hub/ratings/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'processedReports',
          id: args.uid,
        },
      ],
      transformResponse: (response: ProcessedRatingReportDto) => {
        return processedRatingReportsNormalizer(response);
      },
    }),
    editProcessedRatingReport: builder.mutation<
      void,
      { body: EditProcessedRatingReportFormData; uid: string }
    >({
      query: ({ body, uid }) => ({
        url: `charging-hub/ratings/${uid}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['processedReports'],
    }),
  }),
});

export const {
  useCreateProcessedRatingReportMutation,
  useGetProcessedRatingReportByReportUidQuery,
  useEditProcessedRatingReportMutation,
} = chargingHubProcessedReportsApi;
