import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { ChargingStationsModel } from './chargingStationsModel';
import { chargingStationsNormalizer } from './normalizers/chargingStationsNormalizer';
import { ChargingStationsPageEntry } from './chargingStationsPageEntry';
import { chargingStationDetailsNormalizer } from './normalizers/chargingStationDetailsNormalizer';
import { ChargingStationDetails } from './chargingStationDetailsModel';

type GetChargingStationsBody = {
  pageNumber: number;
  pageSize: number;
  searchValue?: string;
};

type AddChargingStationBody = {
  identityKey: string;
  name: string;
  chargingHubUid: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  chargingStationVendor?: string;
  chargingStationVendorModel?: string;
};

export const chargingStationsApi = createApi({
  reducerPath: 'chargingStationsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/charging-stations`),
  tagTypes: ['ChargingStations', 'ChargingStationDetails'],
  endpoints: (builder) => ({
    getChargingStations: builder.query<
      ChargingStationsModel,
      GetChargingStationsBody
    >({
      query: (searchParams) => ({
        url: '/search',
        params: searchParams,
      }),
      providesTags: ['ChargingStations'],
      transformResponse: (chargingStations: ChargingStationsPageEntry) => {
        return {
          totalElements: chargingStations.totalElements ?? 0,
          totalPages: chargingStations.totalPages ?? 0,
          content: chargingStationsNormalizer(chargingStations.content ?? []),
        };
      },
    }),
    getChargingStationDetails: builder.query<ChargingStationDetails, string>({
      query: (identityKey) => `/${identityKey}`,
      providesTags: (result) => [
        { type: 'ChargingStationDetails', id: result?.id },
      ],
      transformResponse: chargingStationDetailsNormalizer,
    }),
    addChargingStation: builder.mutation<void, AddChargingStationBody>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['ChargingStations'],
    }),
  }),
});

export const {
  useGetChargingStationsQuery,
  useGetChargingStationDetailsQuery,
  useAddChargingStationMutation,
} = chargingStationsApi;
