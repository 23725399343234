import { ChargingHubDetailsModel } from '../chargingHubDetailsModel';
import { ChargingHubDetailsDto } from '../chargingHubDetailsDto';
import {
  AuxiliaryFacilityDto,
  AuxiliaryFacilityModel,
} from '@energy-stacks/obelis/shared';

export const chargingHubDetailsNormalizer = (
  chargingHubDetails: ChargingHubDetailsDto
): ChargingHubDetailsModel => {
  const {
    chargingHubUid,
    name,
    address,
    postalCode,
    city,
    state,
    coordinates,
    operator,
    owner,
    auxiliaryFacilities,
    negligibleDamages,
    lastUpdated,
  } = chargingHubDetails;

  return {
    id: chargingHubUid,
    name,
    address,
    postalCode,
    city,
    state,
    coordinates,
    operator,
    owner,
    auxiliaryFacilities: (auxiliaryFacilities ?? []).map((af) =>
      convertAuxiliaryFacilityDtoToModel(af)
    ),
    negligibleDamages,
    lastUpdated,
  };
};

const convertAuxiliaryFacilityDtoToModel = (
  auxiliaryFacility: AuxiliaryFacilityDto
): AuxiliaryFacilityModel => {
  return auxiliaryFacilitiesDtoToModelMap[auxiliaryFacility];
};

export const auxiliaryFacilitiesDtoToModelMap: Record<
  AuxiliaryFacilityDto,
  AuxiliaryFacilityModel
> = {
  ROOF: 'roof',
  FOOD_SERVICE: 'foodService',
  SEATING: 'seating',
  SANITATION: 'sanitation',
  VEHICLE_SUPPLIES_AIR_PRESSURE: 'airPressure',
  VEHICLE_SUPPLIES_WINDSHIELD_WIPER_SYSTEM: 'windshieldWiperSystem',
  VEHICLE_SUPPLIES_VACUUM_CLEANER: 'vacuumCleaner',
  GREEN_ROOF: 'greenRoof',
  PHOTOVOLTAIC_SYSTEM: 'photovoltaicSystem',
} as const;

export const auxiliaryFacilitiesModelToDtoMap: Record<
  AuxiliaryFacilityModel,
  AuxiliaryFacilityDto
> = {
  roof: 'ROOF',
  foodService: 'FOOD_SERVICE',
  seating: 'SEATING',
  sanitation: 'SANITATION',
  airPressure: 'VEHICLE_SUPPLIES_AIR_PRESSURE',
  windshieldWiperSystem: 'VEHICLE_SUPPLIES_WINDSHIELD_WIPER_SYSTEM',
  vacuumCleaner: 'VEHICLE_SUPPLIES_VACUUM_CLEANER',
  greenRoof: 'GREEN_ROOF',
  photovoltaicSystem: 'PHOTOVOLTAIC_SYSTEM',
} as const;
