import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/lab';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { months } from './monthsModel';

export const MonthPicker = ({
  date,
  setDate,
}: {
  date: Date;
  setDate: (date: Date) => void;
}) => {
  const { t } = useTranslation('chargingReports');
  const [open, setOpen] = useState(false);
  const MAX_DATE = new Date();
  const MIN_DATE = new Date(2024, 0, 1);

  const onMonthChange = (newValue: Date | null) => {
    if (newValue) {
      setDate(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        openTo="month"
        views={['month', 'year']}
        value={date}
        onChange={onMonthChange}
        open={open}
        onClose={() => setOpen(false)}
        maxDate={MAX_DATE}
        minDate={MIN_DATE}
        renderInput={(params) => (
          <TextField
            onClick={() => setOpen(true)}
            {...params}
            helperText={null}
            onKeyDown={(e) => e.preventDefault()}
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
              sx: { pointerEvents: 'none' },
              readOnly: true,
            }}
            inputProps={{
              ...params.inputProps,
              value:
                date === null
                  ? ''
                  : `${t(
                      months[date.getMonth()].toLowerCase()
                    )} ${date.getFullYear()}`,
            }}
            sx={{
              alignItems: 'center',
              '& .MuiInputBase-root': {
                paddingX: 1,
              },
              cursor: 'pointer',
              input: {
                textAlign: 'center',
                fontWeight: 400,
                fontSize: 14,
                lineHeight: 20,
                textTransform: 'uppercase',
                paddingX: 0,
              },
            }}
          />
        )}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [70, 0],
              },
            },
          ],
        }}
      />
    </LocalizationProvider>
  );
};
