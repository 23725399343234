import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import { ESButton } from '@energy-stacks/core/ui';
import { useAppLocation } from '@energy-stacks/shared';

export const CreateReportButton = () => {
  const navigate = useNavigate();
  const location = useAppLocation();
  const { t } = useTranslation('chargingStationReports');

  const onClickHandler = useCallback(() => {
    navigate('', {
      state: { background: location, shouldBackgroundFreeze: true },
    });
  }, [location, navigate]);

  return (
    <ESButton
      data-testid="createReportButton"
      onClick={onClickHandler}
      sx={{
        width: '210px',
      }}
      startIcon={<IconPlus />}
      disabled
    >
      {t('createReportButtonLabel')}
    </ESButton>
  );
};
